import React, { useContext, useState, useEffect } from "react";
import { CartItemsContext } from "./CartItemsContext";
import { WishItemsContext } from "./WishItemsContext";

const WishItemsProvider = (props) => {
    // Загружаем избранное из localStorage при инициализации
    const [wishItems, setWishItems] = useState(() => {
        const savedItems = localStorage.getItem("wishlistItems");
        return savedItems ? JSON.parse(savedItems) : [];
    });

    // Сохраняем в localStorage при каждом изменении wishItems
    useEffect(() => {
        localStorage.setItem("wishlistItems", JSON.stringify(wishItems));
    }, [wishItems]);

    const cartItems = useContext(CartItemsContext);

    const addToCartHandler = (item) => {
        cartItems.addItem(item, 1);
    };

    const addToWishHandler = (item) => {
    
        if (!item || !item._id) {
            console.error("Invalid item format:", item);
            return;
        }

        setWishItems((prevItems) => {
            // Удаляем дубликаты
            const filteredItems = prevItems.filter(prevItem => prevItem._id !== item._id);
            return [...filteredItems, { 
                _id: item._id,
                name: item.name,
                price: item.price,
                image: item.image,
                category: item.category,
                size: item.size || "default",
                itemQuantity: 1
            }];
        });
    };

    const removeFromWishHandler = (itemId) => {
        setWishItems((prevItems) => prevItems.filter((prevItem) => prevItem._id !== itemId));
    };

    const wishItemsCtx = {
        items: wishItems,
        addItem: addToWishHandler,
        removeItem: removeFromWishHandler,
        addToCart: addToCartHandler,
    };

    return (
        <WishItemsContext.Provider value={wishItemsCtx}>
            {props.children}
        </WishItemsContext.Provider>
    );
};

export default WishItemsProvider;