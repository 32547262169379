import React, { useState, useEffect, useCallback, useMemo, createContext } from "react";

export const CartItemsContext = createContext({
    items: [],
    totalAmount: 0,
    addItem: () => console.error('Default addItem called'),
    removeItem: () => console.error('Default removeItem called'),
    removeFromCart: () => console.error('Default removeFromCart called'),
    quantity: () => console.error('Default quantity called'),
    clearCart: () => console.error('Default clearCart called'),
    addGiftPack: () => console.error('Default addGiftPack called'),
    removeGiftPack: () => console.error('Default removeGiftPack called'),
});

export const CartItemsProvider = ({ children }) => {
    const [initialized, setInitialized] = useState(false);
    const [cartItems, setCartItems] = useState([]);

    const addItem = useCallback((item) => {
        setCartItems((prevItems) => {
            const existingIndex = prevItems.findIndex((i) => i._id === item._id);
            if (existingIndex >= 0) {
                const updatedItem = { 
                    ...prevItems[existingIndex], 
                    quantity: prevItems[existingIndex].quantity + (item.quantity || 1) 
                };
                return prevItems.map((i, index) => 
                    index === existingIndex ? updatedItem : i
                );
            }
            return [...prevItems, { 
                ...item, 
                quantity: item.quantity || 1,
                type: item.type || "-",
                description: item.description || "-"
            }];
        });
    }, []);

    const removeItem = useCallback((item) => {
        setCartItems((prevItems) => 
            prevItems.filter((i) => i._id !== item._id)
        );
    }, []);

    const removeFromCart = useCallback((id) => {
        removeItem({ _id: id });
    }, [removeItem]);

    const quantity = useCallback((id, action) => {
        setCartItems((prevItems) =>
            prevItems.map((item) => {
                if (item._id === id) {
                    const newQuantity = action === 'INC' 
                        ? item.quantity + 1 
                        : Math.max(item.quantity - 1, 1);
                    return { ...item, quantity: newQuantity };
                }
                return item;
            })
        );
    }, []);

    const addGiftPack = useCallback(() => {
        setCartItems((prevItems) => {
            if (prevItems.some(item => item._id === 'gift-pack')) {
                return prevItems;
            }
        
            return [...prevItems, { 
                _id: '376',
                name: 'Подарочная упаковка',
                description: 'gift-pack',
                price: 150,
                quantity: 1,
				size: "xxl",
                type: "Упаковка",
                image: [{ filename: '1743791068210-684376995-new_image_0.webp' }] // Добавляем изображение по умолчанию
            }];
        });
    }, []);

    const removeGiftPack = useCallback(() => {
        setCartItems((prevItems) => 
            prevItems.filter((i) => i._id !== 'gift-pack')
        );
    }, []);

    const clearCart = useCallback(() => {
        setCartItems([]);
    }, []);

    const totalAmount = useMemo(() => cartItems.reduce(
        (sum, item) => sum + (item.price * item.quantity), 
        0
    ), [cartItems]);

    useEffect(() => {
        try {
            const savedCart = localStorage.getItem('cart');
            if (savedCart) {
                const parsedCart = JSON.parse(savedCart);
                if (Array.isArray(parsedCart)) {
                    setCartItems(parsedCart);
                }
            }
        } catch (error) {
            console.error("Failed to load cart from localStorage:", error);
        } finally {
            setInitialized(true);
        }
    }, []);

    useEffect(() => {
        if (initialized) {
            try {
                localStorage.setItem('cart', JSON.stringify(cartItems));
            } catch (error) {
                console.error("Failed to save cart to localStorage:", error);
            }
        }
    }, [cartItems, initialized]);

    const contextValue = useMemo(() => ({
        items: cartItems,
        totalAmount,
        addItem,
        removeItem,
        removeFromCart,
        quantity,
        clearCart,
        addGiftPack,
        removeGiftPack
    }), [cartItems, totalAmount, addItem, removeItem, removeFromCart, quantity, clearCart, addGiftPack, removeGiftPack]);

    return (
        <CartItemsContext.Provider value={contextValue}>
            {initialized ? children : null}
        </CartItemsContext.Provider>
    );
};