import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import './Landing.css';

const Landing = ({ banners }) => {
    const [currentBanner, setCurrentBanner] = useState('');

    const updateBanner = useCallback((banners) => {
        if (!banners || banners.length === 0) return;
        
        const width = window.innerWidth;
        let bannerUrl = '';

        if (width >= 2560) {
            bannerUrl = banners.find(banner => banner.resolution === '2500')?.image_url;
        } else if (width >= 1920) {
            bannerUrl = banners.find(banner => banner.resolution === '2501')?.image_url;
        } else if (width >= 1440) {
            bannerUrl = banners.find(banner => banner.resolution === '1501')?.image_url;
        } else if (width >= 768) {
            bannerUrl = banners.find(banner => banner.resolution === '1502')?.image_url;
        } else {
            bannerUrl = banners.find(banner => banner.resolution === '1502')?.image_url;
        }

        setCurrentBanner(bannerUrl || '');
    }, []);

    useEffect(() => {
        if (banners && banners.length > 0) {
            updateBanner(banners);
        }
    }, [banners, updateBanner]);

    useEffect(() => {
        const handleResize = () => {
            updateBanner(banners);
        };
        
        window.addEventListener('resize', handleResize);
        
        return () => window.removeEventListener('resize', handleResize);
    }, [banners, updateBanner]);

    return (
        <div className="landing__container">
            <div className="landing__image__container">
                {currentBanner && (
                    <Link
                        to="/all"
                        className="landing__image"
                        style={{ backgroundImage: `url(https://carabine.shop/api/public${currentBanner})` }}
                    ></Link>
                )}
            </div>
        </div>
    );
}

export default Landing;